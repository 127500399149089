import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const SocialLink = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            instagram
            note
            github
          }
        }
      }
    }
  `)
  const social = data.site.siteMetadata?.social

  return (
    <div className="social-link">
      <a href={`https://instagram.com/${social?.instagram || ``}`} target="_blank" rel="noreferrer" >
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../images/instagram.png"
          width={20}
          height={20}
          quality={95}
          alt="instagram"
          />
      </a>
      <a href={`https://note.com/${social?.note || ``}`} target="_blank"rel="noreferrer"  >
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../images/note.png"
          width={20}
          height={20}
          quality={95}
          alt="note"
          />
      </a>
      <a href={`https://github.com/${social?.github || ``}`} target="_blank" rel="noreferrer" >
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../images/github.png"
          width={20}
          height={20}
          quality={95}
          alt="github"
          />
      </a>
    </div>
  );
};

export default SocialLink