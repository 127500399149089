import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { slide as Menu } from "react-burger-menu";
import SocialLink from "../components/socialLink"

export default props => {
  return (
    <Menu {...props}>
      <AniLink fade to="/" className="menu-item" >
        top
      </AniLink>

      <AniLink fade to="/bio" className="menu-item" >
        biography
      </AniLink>

      <AniLink fade to="/blog" className="menu-item" >
        blog
      </AniLink>

      <AniLink fade to="https://mayu-watanabe.com/journal" className="menu-item" target="_blank">
        journal
      </AniLink>

      <AniLink fade to="https://lachrymalfluid.stores.jp/" className="menu-item" target="_blank" >
        shop
      </AniLink>

      <div className="menu-social-link">
        <SocialLink/>
      </div>
    </Menu>
  );
};